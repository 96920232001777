
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import { Role } from "@/models/role.interface";
import { Privilege } from "@/models/privilege.interface";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Status } from "@/models/status.interface";
import { StyleCheck } from "@/mixins/style-check";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, DotsMenu },
})
export default class Roles extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  StyleCheck
) {
  $refs!: {
    roleForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  loadingDT = false;
  dialog = false;
  dialogDelete = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  defaultRole: Role = {
    id: undefined,
    name: "",
    deletable: false,
    status: {
      name: "",
    },
  };
  role: Role = { ...this.defaultRole };
  listOfPrivileges: Privilege[] | undefined = [];
  search = "";
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async searchRoleByText(clear: boolean) {
    this.loadingDT = true;
    if (this.search == null || clear === true) {
      this.search = "";
    }

    await this.getRoles(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getRoles(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getRoles(page: number, size: number, text = ""): Promise<void> {
    await this.$store
      .dispatch("roles/getRoles", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Roles.fetchError.roles"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async getPrivileges(): Promise<void> {
    await this.$store.dispatch("roles/getPrivileges").catch(() => {
      const Error: Notification = {
        message: this.$tc("Roles.fetchError.privileges"),
        timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
        top: true,
        bottom: false,
        left: false,
        right: false,
        currentPath: this.$route.fullPath,
        error: true,
      };

      this.$store.dispatch("notifications/showNotification", Error);
    });
  }

  private async created() {
    this.loader = true;
    await this.getRoles(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    await this.getPrivileges();
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Roles.headers.name"),
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Roles.headers.status"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Roles.headers.quantity"),
        value: "quantity",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Roles.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get privileges(): Privilege[] {
    let response = this.$store.getters["roles/getPrivileges"];
    const formatedPrivileges = response.map((privilege: Privilege) => {
      return { name: this.readablePrivilege(privilege), id: privilege.id };
    });
    return formatedPrivileges;
  }

  private get roles(): Role[] {
    let response = this.$store.getters["roles/getRoles"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.roles) {
      const rolesWithoutClient: any = [];

      response.roles.forEach((role) => {
        if (role.name !== this.$constants.USER_TYPE.CLIENT) {
          rolesWithoutClient.push(role);
        }
      });
      return rolesWithoutClient;
    } else {
      return [];
    }
  }

  private async createRole() {
    this.loading = true;
    if (this.$refs.roleForm.validate()) {
      await this.$store
        .dispatch("roles/createRole", {
          role: this.role,
          privileges: this.listOfPrivileges,
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("Roles.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getRoles(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Roles.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private async editRole(role: Role) {
    this.role = { ...role };
    this.role.status = { ...role.status } as Status;
    this.listOfPrivileges = this.role.privilege;
    this.dialog = true;
  }

  private async updateRole() {
    this.loading = true;
    if (this.$refs.roleForm.validate()) {
      await this.$store
        .dispatch("roles/updateRole", {
          role: this.role,
          privileges: this.listOfPrivileges,
        })
        .then(async () => {
          await this.$store.dispatch("roles/updateRoleStatus", {
            id: this.role.id as number,
            status: this.role.status?.name,
          });
          const Success: Notification = {
            message: this.$tc("Roles.successUpdate"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getRoles(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Roles.errorUpdate"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private async deleteRoleConfirm(role: Role) {
    this.role = { ...role };
    this.dialogDelete = true;
  }

  private async roleDetail(role: Role) {
    this.$router.push(`/staff/roles/${role.id}/detail`);
  }

  private async deleteRole() {
    this.loading = true;
    await this.$store
      .dispatch("roles/updateRoleStatus", {
        id: this.role.id as number,
        status: this.$constants.STATUS.DELETED,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Roles.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getRoles(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Roles.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.role = Object.assign(this.role, this.defaultRole);
    this.listOfPrivileges = [];
  }

  private get formTitle() {
    return !this.role.id
      ? this.$tc("Roles.createTitle")
      : this.$tc("Roles.editTitle");
  }

  resetValidations() {
    this.resetFormValidations([this.$refs.roleForm]);
  }

  private close() {
    this.dialog = false;
    this.role = Object.assign(this.role, this.defaultRole);
    this.listOfPrivileges = [];
    this.resetValidations();
  }

  private get addAllPrivileges() {
    return (this.listOfPrivileges as any).length === this.privileges.length;
  }

  private get addSomePrivileges() {
    return (this.listOfPrivileges as any).length > 0 && !this.privileges;
  }

  private toggle() {
    this.$nextTick(() => {
      if (this.addAllPrivileges) {
        this.listOfPrivileges = [];
      } else {
        this.listOfPrivileges = this.privileges.slice() as never;
      }
    });
  }

  private get icon() {
    if (this.addAllPrivileges) return "mdi-close-box";
    if (this.addSomePrivileges) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }
}
